<template>
  <div>
    <el-row>
      <el-col>
	      <el-form :inline='true'>
		      <el-form-item >
			      <el-select v-model="filters.examSeatTypeId" style="width: 120px" placeholder="考位类型" filterable clearable>
				      <el-option
						      v-for="item in examPosition"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id"
				      >
				      </el-option>
			      </el-select>
		      </el-form-item>
		      
          <el-form-item label="">
            <el-select v-model="filters.registerStatus" style="width: 120px" placeholder="注册状态" filterable clearable>
              <el-option
                  v-for="item in registerStatus"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
		      <el-form-item label="">
			      <el-select v-model="filters.ddCommonStatus" style="width: 120px" placeholder="启用状态" filterable clearable>
				      <el-option
						      v-for="item in ddCommonStatus"
						      :key="item.value"
						      :label="item.name"
						      :value="item.value"
				      >
				      </el-option>
			      </el-select>
		      </el-form-item>
		      <el-form-item label="">
			      <el-cascader
					      v-model="filters.skillType"
					      :options="organizeNums"
					      :props="defaultProps"
					      lazy
					      :show-all-levels="false"
					      clearable
					      style="width: 80%"
					      :getCheckedNodes = 'true'
					      placeholder="组织管理"
			      >
			      </el-cascader>
		      </el-form-item>
		      <el-form-item>
			      <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
		      </el-form-item>
	      </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
        <el-table
            :data="tableData"
            border
            style="width: 100%"
            v-loading="listLoading"
            ref="multipleTable"
            @current-change='currentChange'
            :highlight-current-row='true'
        >
          <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
          <el-table-column prop="departmentIdValue" label="所属机构"  width='' align="center" ></el-table-column>
          <el-table-column prop="examSeatTypeValue" label="考位类型" width='120' align="center" ></el-table-column>
          <el-table-column prop="name" label="考位名称"  width='120' align="center" ></el-table-column>
          <el-table-column prop="name" label="注册状态"  width='100' align="center" >
	          <template #default='scope'>
		          <el-tag v-if='scope.row.registerStatus === 1' type='success'>已注册</el-tag>
		          <el-tag v-if='scope.row.registerStatus === 2' type='warning'>未注册</el-tag>
	          </template>
          </el-table-column>
          <el-table-column prop="name" label="启用状态"  width='100' align="center" >
	          <template #default='scope'>
		          <el-tag v-if='scope.row.ddCommonStatus === 1' type='success'>启用</el-tag>
		          <el-tag v-if='scope.row.ddCommonStatus === 2' type='danger'>禁用</el-tag>
	          </template>
          </el-table-column>
	        <el-table-column prop="ddIdExamVersionValue" label="版本号" width="100"  align="center" ></el-table-column>
	        <el-table-column prop="machineBindingTime" label="注册时间"  align="center" ></el-table-column>
          <el-table-column prop="lastLoginDuration" label="最后登录时间间隔（天）"  align="center" ></el-table-column>
<!--          <el-table-column prop="examUsedTime" label="国考交卷次数"  align="center" ></el-table-column>-->
<!--          <el-table-column prop="exerciseDuration" label="练习模式使用时长"  align="center" ></el-table-column>-->
          <el-table-column prop="lastUseTime" label="最后一次使用时间"  align="center" ></el-table-column>
        </el-table>
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.dataCount">
      </el-pagination>
    </el-col>
  </div>
</template> 

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
	getAreaDictionaryList, getExamSeatUseDetail,getOrganizationTree,getExamSeatTypeList,
} from '../../api/api'

export default {
  components:{Toolbar},
  data() {
    return {
	    registerStatus:[
		    {value:1,name:'已注册'},
		    {value:2,name:'未注册'},
	    ],
	    ddCommonStatus: [
		    {value:1,name:'启用'},
		    {value:2,name:'禁用'},
	    ],
      buttonList:[],
      tableData: [],
      areaArr: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      filters: {
	      examSeatTypeId: undefined,
	      registerStatus: undefined,
	      ddCommonStatus: undefined,
	      skillType:[],
      },
	    organizeNums:[],
	    defaultProps: {
		    children: "children",
		    hasChildren: "hasChildren",
		    label: "name",
		    value:'id' ,//可要可不要
		    checkStrictly: true,
		    multiple:true,
	    },
	    examPosition:[],
	    



    }
  },
  methods: {
	  
	  //组织管理
	  getOrganizations() {
		  let _this = this
		  const userInfo = JSON.parse(window.localStorage.user)
		  console.log(userInfo,'info')
		  var params = {
			  parentId: userInfo.departmentId
		  }
		  if(this.filters.selectc === ''){}else{
			  params.isEnable = this.filters.selectc
		  }
		  // if(this.filters.name){
		  //   params.name = this.filters.name
		  // }
		  this.listLoading = true
		  getOrganizationTree(params).then(res => {
			  let data = res.data
			  let resData = [data.response]
			  console.log(resData,'res.da')
			  resData.push()
			  if(data.success){
				  
				  this.selectVal = ''
				  this.listLoading = false
				  if(data.response.name == "根节点"){
					  let newObjData = JSON.stringify(data.response.children).replace(/\hasChildren/g,"HasChildren")
					  _this.organizeNums = JSON.parse(newObjData)
					  console.log(_this.organizeNums,'11111')
				  }else{
					  _this.organizeNums = resData
				  }
				  // _this.treeData = resData
			  }
		  })
	  },
	  //考位类型
	  async examTypes(){
		  let params = {
			  pageIndex:1,
			  pageSize:20,
			  ddIdExamSeatStatus:1,
		  }
		  getExamSeatTypeList(params).then(res=>{
			  if (res.data.success){
				  this.examPosition = res.data.response.data
			  }
		  })
		  //组织管理
		  this.getOrganizations()
		  
		  // //职业功能
		  // let params1 = {
		  //   pageIndex:1,
		  //   pageSize:20,
		  // }
		  // getOproomProfessionFuncList(params1).then(res1=>{
		  //   if (res1.data.success){
		  //     this.professionType = res1.data.response.data
		  //   }
		  // })
		  
		  // //职业等级方向
		  // let params2 = {
		  //   pageIndex:1,
		  //   pageSize:20,
		  // }
		  // getOproomProfessionDirList(params2).then(res2=>{
		  //   if (res2.data.success){
		  //     this.registers = res2.data.response.data
		  //   }
		  // })
		  
		  //根据考位类型获取鉴定点个数
		  // let params3 = {
		  //   pageIndex:1,
		  //   pageSize:20,
		  // }
		  // getExamPointNumByExamSeatType(params3).then(res3=>{
		  //   if (res3.data.success){
		  //     this.pointNums = res3.data.response
		  //   }
		  // })
	  },
    rowClass () {
      return 'padding:8px!important;'
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    activeChange(val) {
      if(val == '2') {
        this.viewsApplicationWidth = "1200px"
      } else {
        this.viewsApplicationWidth = "60%"
      }
    },
	  getListData(){
			this.pages={
				pageIndex: 1,
				pageSize: 20
			}
			this.getDataList()
	  },
    // 列表查询接口
    getDataList(){
	    const userInfo = JSON.parse(window.localStorage.user)
	    console.log(this.filters.skillType,'info')
	    const ids = this.filters.skillType.map(item => {
				return item[1]
	    })
	    console.log(ids,'ids')
      let params = {
	      // cityCode:userInfo.cityCode,
	      // districtCode:userInfo.districtCode,
	      // provinceCode:userInfo.provinceCode,
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
	      ddCommonStatus: this.filters.ddCommonStatus,//启用状态：1:启用;2:禁用
	      departmentId: ids.join(',')||userInfo.departmentId,//组织机构id
	      examSeatTypeId:this.filters.examSeatTypeId,//考位类型id
	      registerStatus:this.filters.registerStatus,//注册状态：1:已注册;2:未注册
      }
      this.listLoading = true
	    getExamSeatUseDetail(params).then(res => {
        let result = res.data
        if(result.success) {
          this.tableData = result.response.data
          this.pages.dataCount = result.response.dataCount
	        this.examTypes()
        }
        this.listLoading = false
      })
    },
    // 获取省
    getAreaList() {
      let params = {
        parentId: 1,
      }
      getAreaDictionaryList(params).then(res => {
        if (res.data.success) {
          this.areaArr = res.data.response.reverse();
        }
      })
    },
    // 查询
    getProjectList() {
      this.pages.pageIndex = 1
      this.getListData();
    },


    // 点击table某一行
    currentChange(val) {
      this.needData = val
      // console.log(val,'val')
      const myArray = [];

// 使用push方法将整个对象添加到数组中
      myArray.push(val);
      this.needDataContact = myArray
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getDataList();
    },
    handleSizeChange(val){
      this.pages.pageSize = val;
      this.getDataList();
    },
    // 时间格式化
    formatCreateTime: function(row, column) {
      return !row || row == ""
          ? ""
          : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
    },
    // 表格数据分页条数累计
    indexMethod(index) {
      let currentPage = this.pages.pageIndex
      let pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  },
  watch: {

  },
  created(){
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
	 
	  this.getListData();
    // this.getAreaList()
  }
}
</script>

<style lang="stylus" scoped>
.projectName {
  text-align: center;
}
.projectSelect {
  margin-bottom: 15px;
}
.projectTitle {
  margin-bottom: 15px
}
.replyBtn {
  margin: 10px 0;
  color: #409eff;
  cursor: pointer;
  width: 30px;
}
.replyMessage {
  background: #eee;
  line-height: 30px;
  padding: 5px;
  border-radius: 2px;
  margin: 5px 15px;
}
.titleTimer {
  margin-left: 10px;
}
.green_t {
  color: green;
}
.red_t {
  color: red;
}
</style>
